<template>
  <div class="row">
    <div class="col-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body" style="padding: 2.5rem">
          <div class="text-center">
            <h2>
              <span>Update Profile</span>
              <h6 class="heading-small text-muted py-3text-truncate">
                <span>Help us get to know you better!</span>
              </h6>
            </h2>
          </div>
          <br />

          <form role="form">
            <h6 class="heading-small text-muted text-truncate">
              <span>Personal Info</span>
            </h6>
            <div class="row">
              <div class="col-4">
                <base-input label="First Name" required>
                  <a-input v-model.trim="formData.first_name" />
                  <div v-if="$v.formData.first_name.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.first_name.required">
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>
              <div class="col-3">
                <base-input label="Middle Name">
                  <a-input v-model.trim="formData.middle_name" />
                </base-input>
              </div>

              <div class="col-4">
                <base-input label="Last Name" required>
                  <a-input v-model.trim="formData.last_name" />
                  <div v-if="$v.formData.last_name.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.last_name.required">
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-1">
                <base-input label="Suffix">
                  <a-input v-model.trim="formData.last_name_suffix" />
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <base-input label="Birthday">
                  <div class="row">
                    <div class="col-4">
                      <a-select v-model="birthMonth">
                        <a-select-option
                          v-for="month in options.months"
                          :key="month.key"
                          :value="month.key"
                        >
                          {{ month.name }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Month</i></small>
                    </div>
                    /
                    <div class="col-3">
                      <a-select v-model="birthDay">
                        <a-select-option
                          v-for="day in 31"
                          :key="zeroPad(day, 2)"
                          :value="zeroPad(day, 2)"
                        >
                          {{ zeroPad(day, 2) }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Day</i></small>
                    </div>
                    /
                    <div class="col-4">
                      <a-select v-model="birthYear">
                        <a-select-option
                          v-for="year in 101"
                          :key="parseInt(yearStart) + parseInt(year)"
                          :value="parseInt(yearStart) + parseInt(year)"
                        >
                          {{ parseInt(yearStart) + parseInt(year) }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Year</i></small>
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-4">
                <base-input label="Gender">
                  <br />
                  <a-select v-model="formData.gender">
                    <a-select-option
                      v-for="gender in options.genders"
                      :key="gender.key"
                      :value="gender.key"
                    >
                      {{ gender.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>

              <div class="col-3">
                <base-input label="Nickname">
                  <a-input v-model.trim="formData.nick_name" />
                </base-input>
              </div>
            </div>
            <hr />
            <h6 class="heading-small text-muted text-truncate">
              <span>Address</span>
            </h6>
            <div class="row">
              <div class="col-3">
                <base-input label="Unit No.">
                  <a-input v-model.trim="formData.location_unit" />
                </base-input>
              </div>
              <div class="col-9">
                <base-input label="Street">
                  <a-input v-model.trim="formData.location_street" />
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <base-input label="Region">
                  <br />
                  <a-select
                    v-model="formData.region_code"
                    @change="onChangeRegion"
                  >
                    <a-select-option
                      v-for="region in options.regions"
                      :key="region.code"
                      :value="region.code"
                    >
                      {{ region.regionName }} - {{ region.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
              <div class="col-3" v-if="!isRegionNCR">
                <base-input label="Province">
                  <br />
                  <a-select
                    v-model="formData.province_code"
                    @change="onChangeProvince"
                  >
                    <a-select-option
                      v-for="province in options.provinces"
                      :key="province.code"
                      :value="province.code"
                    >
                      {{ province.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
              <div class="col-3">
                <base-input label="City">
                  <br />
                  <a-select v-model="formData.city_code" @change="onChangeCity">
                    <a-select-option
                      v-for="city in options.cities"
                      :key="city.code"
                      :value="city.code"
                    >
                      {{ city.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
              <div class="col-3">
                <base-input label="Barangay">
                  <br />
                  <a-select
                    v-model="formData.barangay_code"
                    @change="onChangeBarangay"
                  >
                    <a-select-option
                      v-for="barangay in options.barangays"
                      :key="barangay.code"
                      :value="barangay.code"
                    >
                      {{ barangay.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
            </div>
            <hr />
            <h6 class="heading-small text-muted text-truncate">
              <span>Contact Details</span>
            </h6>
            <div class="row">
              <div class="col-6">
                <base-input label="Mobile Number" required>
                  <!-- <a-input v-model.trim="formData.mobile_no" /> -->
                  <a-input-group compact>
                    <a-select
                      default-value="+63"
                      style="width: 15%"
                      v-model="countryCode"
                    >
                      <a-select-option value="+63">
                        +63
                      </a-select-option>
                    </a-select>
                    <a-input
                      v-model="mobileNo"
                      style="width: 85%"
                      placeholder="9XXXXXXXXX"
                    />
                  </a-input-group>
                  <div v-if="$v.formData.mobile_no.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.mobile_no.required">
                      Field is required
                    </div>
                    <div
                      class="error"
                      v-else-if="!$v.formData.mobile_no.mobileNoFormat"
                    >
                      Invalid mobile number format. Use +639XXXXXXXXX format.
                    </div>
                  </div>
                </base-input>
              </div>
              <div class="col-6">
                <base-input label="Email Address">
                  <a-input v-model.trim="formData.email" />
                </base-input>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-12">
                <a-checkbox v-model="dpcConsent">
                  <span
                    >By proceeding, you agree to the
                    <a href="javascript:void(0)'" v-b-modal.modalDpc
                      >Data Privacy Policy</a
                    >
                    of our company.</span
                  >
                </a-checkbox>
                <div v-if="$v.formData.dpc_consent.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.dpc_consent.required">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.formData.dpc_consent.mustBeTrue">
                    Must accept company's data privacy policy.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="text-right">
              <router-link
                :to="{
                  name: 'Rewards',
                  params: { siteName: this.clpSitePageSettings.siteName }
                }"
                tag="button"
                class="btn btn-secondary"
                :disabled="formSubmitting"
              >
                Skip for now
              </router-link>
              <b-button
                variant="primary"
                @click="updateProfile"
                :disabled="formSubmitting"
              >
                <span v-if="!formSubmitting">Update Profile</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal id="modalDpc" title="Data Privacy Policy" ok-only size="lg">
      <PdfViewer :pdfUrl="dataPrivacyFilePath" />
    </b-modal>
  </div>
</template>
<script>
import PSGCApi from '../../controllers/psgcApi';
import HTTPRequest from '../../controllers/httpRequest';
import secureStorage from '../../controllers/secureStorage';
import { required } from 'vuelidate/lib/validators';
import PdfViewer from '@/components/PdfViewer.vue';
import moment from 'moment';

const ENC_SECRET = process.env.VUE_APP_ENC_SECRET;

export default {
  name: 'YourProfile',
  props: {
    clpSitePageSettings: Object
  },
  components: {
    PdfViewer
  },
  data() {
    return {
      dataPrivacyFilePath: null,
      policyModal: null,
      countryCode: '+63',
      mobileNo: null,
      formData: {
        membership_program_id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        last_name_suffix: '',
        nick_name: '',
        mobile_no: '',
        email: '',
        location_unit: '',
        location_street: '',
        location_barangay: '',
        barangay_code: null,
        location_city: '',
        city_code: null,
        location_province: '',
        province_code: null,
        location_region: '',
        region_code: null,
        location_country: 'PH',
        gender: null,
        birth_month: null,
        birth_year: null,
        birth_day: null,
        dpc_consent: false
      },
      options: {
        genders: [
          { key: 'M', name: 'Male' },
          { key: 'F', name: 'Female' }
        ],
        months: [
          { key: '01', name: 'January' },
          { key: '02', name: 'Febuary' },
          { key: '03', name: 'March ' },
          { key: '04', name: 'April ' },
          { key: '05', name: 'May ' },
          { key: '06', name: 'June' },
          { key: '07', name: 'July ' },
          { key: '08', name: 'August ' },
          { key: '09', name: 'September' },
          { key: '10', name: 'October' },
          { key: '11', name: 'November' },
          { key: '12', name: 'December' }
        ],
        regions: [],
        provinces: [],
        cities: [],
        barangays: []
      },
      birthMonth: null,
      birthYear: null,
      birthDay: null,
      yearStart: moment().year() - 101,
      ENC_SECRET,
      formSubmitting: false,
      isRegionNCR: false
    };
  },
  validations: {
    formData: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      mobile_no: {
        required,
        mobileNoFormat(value) {
          const regex = /^(\+639)\d{9}$/;
          return regex.test(value);
        }
      },
      dpc_consent: {
        required,
        mustBeTrue(value) {
          return value;
        }
      }
    }
  },
  methods: {
    async updateProfile() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$invalid) {
        this.formSubmitting = true;

        const response = await HTTPRequest.putRequest('/api/v2/clp_members/', {
          member: this.formData
        });

        if (response.id) {
          this.$notification.success({
            message: 'Profile has been updated.'
          });

          secureStorage.setItem('clpMemberData', response);

          setTimeout(() => {
            this.$router.push({
              name: 'Rewards',
              params: { siteName: this.clpSitePageSettings.siteName }
            });
          }, 1000);
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.formSubmitting = false;
      }
    },

    async getRegions() {
      this.options.regions = await PSGCApi.getRegions();
    },

    async getProvinces(regionCode) {
      this.isRegionNCR = regionCode == PSGCApi.NCR_REGION_CODE ? true : false;
      if (this.isRegionNCR) {
        this.options.cities = await PSGCApi.getProvinces(regionCode);
      } else {
        this.options.provinces = await PSGCApi.getProvinces(regionCode);
      }
    },

    async getCities(provinceCode) {
      this.options.cities = await PSGCApi.getCities(provinceCode);
    },

    async getBarangays(cityCode) {
      this.options.barangays = await PSGCApi.getBarangays(cityCode);
    },

    zeroPad(num, places) {
      return String(num).padStart(places, '0');
    },

    setFormData(data) {
      if (data != null) {
        this.getProvinces(data.region_code);
        this.getCities(data.province_code);
        this.getBarangays(data.city_code);
        const {
          membership_program_id,
          membershipProgram,
          first_name,
          middle_name,
          last_name,
          last_name_suffix,
          nick_name,
          allow_null_mobile_no,
          mobile_no,
          email,
          location_unit,
          location_street,
          location_barangay,
          barangay_code,
          location_city,
          city_code,
          location_municipality,
          municipality_code,
          location_province,
          province_code,
          location_region,
          region_code,
          location_country,
          gender,
          birth_month,
          birth_year,
          birth_day,
          dpc_consent
        } = data;

        this.formData = {
          membership_program_id,
          membershipProgram,
          first_name,
          middle_name,
          last_name,
          last_name_suffix,
          nick_name,
          allow_null_mobile_no,
          mobile_no,
          email,
          location_unit,
          location_street,
          location_barangay,
          barangay_code,
          location_city,
          city_code,
          location_municipality,
          municipality_code,
          location_province,
          province_code,
          location_region,
          region_code,
          location_country,
          gender,
          birth_month,
          birth_year,
          birth_day,
          dpc_consent
        };

        this.birthMonth =
          birth_month != null ? moment(birth_month).format('MM') : null;
        this.birthYear =
          birth_year != null ? moment(birth_year).format('YYYY') : null;
        this.birthDay =
          birth_day != null ? moment(birth_day).format('DD') : null;

        if (mobile_no != '-1') {
          this.countryCode = mobile_no.substring(0, 3);
          this.mobileNo = mobile_no.substr(-10);
        } else {
          this.countryCode = '+63';
          this.mobileNo = mobile_no;
        }
      }
    },

    onChangeRegion() {
      this.formData.location_region = event.target.innerText;
      this.formData.province_code = null;
      this.formData.location_province = null;
      this.options.provinces = [];
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.options.cities = [];
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.options.barangays = [];
      this.getProvinces(this.formData.region_code);
    },

    onChangeProvince() {
      this.formData.location_province = event.target.innerText;
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.options.cities = [];
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.options.barangays = [];
      this.getCities(this.formData.province_code);
    },

    onChangeCity() {
      this.formData.location_city = event.target.innerText;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.options.barangays = [];
      this.getBarangays(this.formData.city_code);
    },

    onChangeBarangay() {
      this.formData.location_barangay = event.target.innerText;
    },

    async getMemberData() {
      const res = await HTTPRequest.getRequest('/api/v2/clp_members/me');

      if (res) {
        this.setFormData(res);
      } else {
        this.setFormData(null);
      }
    }
  },

  created() {
    this.getMemberData();

    this.getRegions();

    this.dataPrivacyFilePath = '/' + this.clpSitePageSettings.policy.filePath;
  },

  computed: {
    dpcConsent: {
      get() {
        return this.formData.dpc_consent ? true : false;
      },
      set(val) {
        this.formData.dpc_consent = val;
      }
    }
  },

  watch: {
    birthMonth: function(month) {
      if (month != null) {
        let year = moment().year();
        let day = moment().day();
        this.formData.birth_month = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_month = null;
      }
    },

    birthYear: function(year) {
      if (year != null) {
        let month = moment().month() + 1;
        let day = moment().day();
        this.formData.birth_year = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_year = null;
      }
    },

    birthDay: function(day) {
      if (day != null) {
        let month = moment().month() + 1;
        let year = moment().year();
        this.formData.birth_day = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_day = null;
      }
    },

    mobileNo: function(mobile) {
      if (mobile != null) {
        this.formData.mobile_no = this.countryCode + mobile;
      } else {
        this.formData.mobile_no = null;
      }
    }
  }
};
</script>
