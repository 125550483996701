<template>
  <div class="row">
    <div class="col-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body" style="padding: 2.5rem">
          <div class="text-center">
            <h2>
              <span>Create Profile</span>
              <h6 class="heading-small text-muted py-3text-truncate">
                <span>Help us get to know you better!</span>
              </h6>
            </h2>
          </div>
          <br />

          <form role="form">
            <h6 class="heading-small text-muted text-truncate">
              <span>Personal Info</span>
            </h6>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <base-input label="First Name" required>
                  <a-input v-model="formData.first_name" :disabled="lockForm" />
                  <div v-if="$v.formData.first_name.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.first_name.required">
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="Middle Name">
                  <a-input
                    v-model="formData.middle_name"
                    :disabled="lockForm"
                  />
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <base-input label="Last Name" required>
                  <a-input v-model="formData.last_name" :disabled="lockForm" />
                  <div v-if="$v.formData.last_name.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.last_name.required">
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-1 col-lg-1">
                <base-input label="Suffix">
                  <a-input
                    v-model="formData.last_name_suffix"
                    :disabled="lockForm"
                  />
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                <base-input label="Birthday">
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                      <a-select v-model="birthMonth" @change="populateDaysSelection" :disabled="lockForm">
                        <a-select-option
                          v-for="month in options.months"
                          :key="month.key"
                          :value="month.key"
                        >
                          {{ month.name }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Month</i></small>
                    </div>
                    /
                    <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                      <a-select v-model="birthDay" :disabled="lockForm">
                        <a-select-option
                            v-for="day in options.days"
                            :key="day"
                            :value="day"
                        >
                          {{ day }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Day</i></small>
                    </div>
                    /
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                      <a-select v-model="birthYear" @change="populateDaysSelection" :disabled="lockForm">
                        <a-select-option
                          v-for="year in 101"
                          :key="parseInt(yearStart) + parseInt(year)"
                          :value="parseInt(yearStart) + parseInt(year)"
                        >
                          {{ parseInt(yearStart) + parseInt(year) }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Year</i></small>
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <base-input label="Gender">
                  <br />
                  <a-select v-model="formData.gender" :disabled="lockForm">
                    <a-select-option
                      v-for="gender in options.genders"
                      :key="gender.key"
                      :value="gender.key"
                    >
                      {{ gender.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="Nickname">
                  <a-input v-model="formData.nick_name" :disabled="lockForm" />
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <base-input label="Password" required>
                  <a-input-password
                      placeholder="Enter Password"
                      :disabled="lockForm"
                      v-model="formData.password"
                  />
                  <div v-if="$v.formData.password.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.password.required">
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>
            </div>
            <hr />
            <h6 class="heading-small text-muted text-truncate">
              <span>Address</span>
            </h6>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="Unit No.">
                  <a-input
                    v-model="formData.location_unit"
                    :disabled="lockForm"
                  />
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                <base-input label="Street">
                  <a-input
                    v-model="formData.location_street"
                    :disabled="lockForm"
                  />
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="Region">
                  <br />
                  <a-select
                    v-model="formData.region_code"
                    @change="onChangeRegion"
                    :disabled="lockForm"
                  >
                    <a-select-option
                      v-for="region in options.regions"
                      :key="region.code"
                      :value="region.code"
                    >
                      {{ region.regionName }} - {{ region.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="Province">
                  <br />
                  <a-select
                    v-model="formData.province_code"
                    @change="onChangeProvince"
                    :disabled="lockForm"
                  >
                    <a-select-option
                      v-for="province in options.provinces"
                      :key="province.code"
                      :value="province.code"
                    >
                      {{ province.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="City">
                  <br />
                  <a-select
                    v-model="formData.city_code"
                    @change="onChangeCity"
                    :disabled="lockForm"
                  >
                    <a-select-option
                      v-for="city in options.cities"
                      :key="city.code"
                      :value="city.code"
                    >
                      {{ city.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <base-input label="Barangay">
                  <br />
                  <a-select
                    v-model="formData.barangay_code"
                    @change="onChangeBarangay"
                    :disabled="lockForm"
                  >
                    <a-select-option
                      v-for="barangay in options.barangays"
                      :key="barangay.code"
                      :value="barangay.code"
                    >
                      {{ barangay.name }}
                    </a-select-option>
                  </a-select>
                </base-input>
              </div>
            </div>
            <hr />
            <h6 class="heading-small text-muted text-truncate">
              <span>Contact Details</span>
            </h6>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <base-input label="Mobile Number" required>
                  <a-input-group compact>
                    <a-select
                      default-value="+63"
                      style="width: 15%"
                      v-model="countryCode"
                      :disabled="lockForm || selfRegistration"
                    >
                      <a-select-option value="+63">
                        +63
                      </a-select-option>
                    </a-select>
                    <a-input
                      v-model="mobileNo"
                      style="width: 85%"
                      placeholder="9XXXXXXXXX"
                      :disabled="lockForm || selfRegistration"
                    />
                  </a-input-group>
                  <div v-if="$v.formData.mobile_no.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.mobile_no.required">
                      Field is required
                    </div>
                    <div
                      class="error"
                      v-else-if="!$v.formData.mobile_no.mobileNoFormat"
                    >
                      Invalid mobile number format. Use +639XXXXXXXXX format.
                    </div>
                  </div>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <base-input label="Email Address">
                  <a-input v-model="formData.email" :disabled="lockForm" />
                </base-input>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-12">
                <!-- <a-checkbox v-model="dpcConsent"> -->
                <span
                  >By signing in, you agree to the
                  <a href="javascript:void(0)'" v-b-modal.modalDpc
                    >Data Privacy Policy</a
                  >
                  of our company.</span
                >
                <!-- </a-checkbox> -->
                <div v-if="$v.formData.dpc_consent.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.dpc_consent.required">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.formData.dpc_consent.mustBeTrue">
                    Must accept company's data privacy policy.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="text-right">
              <b-button
                variant="primary"
                @click="createProfile"
                :disabled="formSubmitting || lockForm"
              >
                <span v-if="!formSubmitting">Create Profile</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal id="modalDpc" title="Data Privacy Policy" ok-only size="lg">
      <PdfViewer :pdfUrl="dataPrivacyFilePath" />
      <div class="row">
        <div class="col-12">
          <a-checkbox v-model="dpcConsent" v-b-modal.modalDpc>
            <span
              >By signing in, you agree to the
              <a href="javascript:void(0)'" v-b-modal.modalDpc
                >Data Privacy Policy</a
              >
              of our company.</span
            >
          </a-checkbox>

          <div v-if="$v.formData.dpc_consent.$dirty" class="text-danger">
            <div class="error" v-if="!$v.formData.dpc_consent.required">
              Field is required
            </div>
            <div class="error" v-if="!$v.formData.dpc_consent.mustBeTrue">
              Must accept company's data privacy policy.
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import PSGCApi from '../../controllers/psgcApi';
import HTTPRequest from '../../controllers/httpRequest';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';
import PdfViewer from '@/components/PdfViewer.vue';
import secureStorage from '../../controllers/secureStorage';
const ENC_SECRET = process.env.VUE_APP_ENC_SECRET;

export default {
  name: 'SelfRegistrationForm',
  props: {
    clpSitePageSettings: Object
  },
  components: {
    PdfViewer
  },
  data() {
    return {
      countryCode: '+63',
      mobileNo: null,
      formData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        password: '',
        last_name_suffix: '',
        nick_name: '',
        mobile_no: '',
        email: '',
        location_unit: '',
        location_street: '',
        location_barangay: '',
        barangay_code: null,
        location_city: '',
        city_code: null,
        location_province: '',
        province_code: null,
        location_region: '',
        region_code: null,
        location_country: 'PH',
        gender: null,
        birth_month: null,
        birth_year: null,
        birth_day: null,
        dpc_consent: false
      },
      options: {
        genders: [
          { key: 'M', name: 'Male' },
          { key: 'F', name: 'Female' }
        ],
        months: [
          { key: '01', name: 'January' },
          { key: '02', name: 'February' },
          { key: '03', name: 'March ' },
          { key: '04', name: 'April ' },
          { key: '05', name: 'May ' },
          { key: '06', name: 'June' },
          { key: '07', name: 'July ' },
          { key: '08', name: 'August ' },
          { key: '09', name: 'September' },
          { key: '10', name: 'October' },
          { key: '11', name: 'November' },
          { key: '12', name: 'December' }
        ],
        days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
        regions: [],
        provinces: [],
        cities: [],
        barangays: []
      },
      birthMonth: null,
      birthYear: null,
      birthDay: null,
      yearStart: moment().year() - 101,
      ENC_SECRET,
      formSubmitting: false,
      selfRegistration: false,
      lockForm: false,
      dataPrivacyFilePath: null
    };
  },
  validations: {
    formData: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      password: {
        required
      },
      mobile_no: {
        required,
        mobileNoFormat(value) {
          const regex = /^(\+639)\d{9}$/;
          return regex.test(value);
        }
      },
      dpc_consent: {
        required,
        mustBeTrue(value) {
          return value;
        }
      }
    }
  },
  methods: {
    async createProfile() {
      this.$v.formData.$touch();

      if (!this.dpcConsent) {
        this.$bvModal.show('modalDpc');
        return;
      }

      if (!this.$v.formData.$invalid && !this.lockForm) {
        this.formSubmitting = true;
        if(this.formData.email == '' || this.formData.email == null) {
          delete this.formData.email;
        }
        this.formData.membership_program_id = this.clpSitePageSettings.membership_program_id;
        const passwordData =  this.formData.password;
        delete this.formData.password;
        const response = await HTTPRequest.postRequest('/api/v2/clp_members/', {
          member: this.formData,
          "password": passwordData,
          create_member_account: true,
          site_page: {
            id: this.clpSitePageSettings.id
          }
        });

        if (response.data && response.data.id) {
          this.$notification.success({
            message: 'Profile has been created.'
          });

          secureStorage.setItem('clpSitePageAccessToken', response.access_token);
          secureStorage.setItem('new_member_id', response.data.id);
          setTimeout(() => {
            this.$router.push({
              name: 'AccountVerification',
              params: { siteName: this.clpSitePageSettings.siteName }
            });
          }, 1000);
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.formSubmitting = false;
      }
    },

    async getRegions() {
      this.options.regions = await PSGCApi.getRegions();
    },

    async getProvinces(regionCode) {
      this.isRegionNCR = regionCode == PSGCApi.NCR_REGION_CODE ? true : false;
      this.options.provinces = await PSGCApi.getProvinces(regionCode);
    },

    async getCities(provinceCode) {
      this.options.cities = await PSGCApi.getCities(provinceCode);
    },

    async getBarangays(cityCode) {
      this.options.barangays = await PSGCApi.getBarangays(cityCode);
    },

    zeroPad(num, places) {
      return String(num).padStart(places, '0');
    },

    populateDaysSelection() {
      const month = this.birthMonth != null ? this.birthMonth : 1;
      const year = this.birthYear != null ? this.birthYear : this.yearStart;
      const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
      const days = [];
      for (let i = 1; i <= daysInMonth; i++) {
        days.push(moment(`${year}-${month}-${i}`, "YYYY-MM-DD").format("DD"));
      }
      if(this.birthDay && !days.includes(this.birthDay)) {
        this.birthDay = null;
      }
      this.options.days = days;
    },
    onChangeRegion() {
      this.formData.location_region = event.target.innerText;
      this.formData.province_code = null;
      this.formData.location_province = null;
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.getProvinces(this.formData.region_code);
    },

    onChangeProvince() {
      this.formData.location_province = event.target.innerText;
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.getCities(this.formData.province_code);
    },

    onChangeCity() {
      this.formData.location_city = event.target.innerText;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.getBarangays(this.formData.city_code);
    },

    onChangeBarangay() {
      this.formData.location_barangay = event.target.innerText;
    }
  },

  created() {
    this.getRegions();
  },

  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.token !== undefined) {
      try {
        const b64decode = Base64.decode(this.$route.query.token);
        const tokenDecrypt = CryptoJS.AES.decrypt(
          b64decode,
          ENC_SECRET
        ).toString(CryptoJS.enc.Utf8);
        const tokenObj = JSON.parse(tokenDecrypt);
        const validity = tokenObj.valid_at;
        const mobileNumber = tokenObj.mobile;
        const now = moment().format('YYYY-MM-DD HH:mm');

        if (validity < now) {
          this.$notification.error({
            message: 'Token Expired.'
          });
          this.selfRegistration = false;
          this.lockForm = true;
        } else {
          this.formData.mobile_no = mobileNumber;
          this.selfRegistration = true;
          this.lockForm = false;
        }
      } catch (error) {
        console.log('Self Registration Error: ', error);
        this.$notification.error({
          message: 'Invalid Token.'
        });
        this.selfRegistration = false;
        this.lockForm = true;
      }
    }

    this.dataPrivacyFilePath = '/' + this.clpSitePageSettings.policy.filePath;
  },

  computed: {
    dpcConsent: {
      get() {
        return this.formData.dpc_consent ? true : false;
      },
      set(val) {
        this.formData.dpc_consent = val;
      }
    }
  },

  watch: {
    birthMonth: function(month) {
      if (month != null) {
        let year = moment().year();
        let day = moment().day();
        this.formData.birth_month = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_month = null;
      }
    },

    birthYear: function(year) {
      if (year != null) {
        let month = moment().month();
        let day = moment().day();
        this.formData.birth_year = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_year = null;
      }
    },

    birthDay: function(day) {
      if (day != null) {
        let month = moment().month();
        let year = moment().year();
        this.formData.birth_day = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_day = null;
      }
    },

    mobileNo: function(mobile) {
      if (mobile != null) {
        this.formData.mobile_no = this.countryCode + mobile;
      } else {
        this.formData.mobile_no = null;
      }
    }
  }
};
</script>
